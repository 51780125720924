import 'reset-css';
import '../styles/app.scss';

import '../img/Logo_Zentrumwest_2018_schwarz.svg';

// import './vendor/jquery.fittext';
import ToTopBtn from "./lib/ToTopBtn";
import AccordionMenu from "./lib/AccordionMenu";
import CookieNotice from "./lib/CookieNotice";
import AccordionElement from "./lib/AccordionElement";

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}

function initComponents() {
    // detect non-touch (e.g. desktop) devices
    var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    if (!touchsupport) { // browser doesn't support touch
        document.documentElement.className += " non-touch"
    }

    // To Top Button
    var toTopBtn = new ToTopBtn();

    // Cookie Notice
    var cookieNotice = new CookieNotice();

    // jQuery FitText plugin
    // $('.text-super').fitText();

    // Accordion menus
    $('.menu').each((index, element) => {
        new AccordionMenu(element);
    });

    // Accordion elements (e.g. in archive)
    $('.accordion').each((index, value) => {
        new AccordionElement(value);
    });

    // Full size page dimensions
    $(window).on('resize', (event) => {
        calcVariableFontAttr();
        calcHeights();
    });
    $(window).trigger('resize');
}

function calcHeights() {
    const textSuperHeight = ($(window).height() - 3 * $('.menu').outerHeight()) / 3;
    const imageSuperHeight = textSuperHeight * 2;
    // may be used later to scale speaker images
    // const speakerImageHeight = imageSuperHeight * 0.85;

    $('.text-super-wrapper').css('height', textSuperHeight);
    $('.image-super-wrapper').css('height', imageSuperHeight);
    // $('.speaker-image img').css('height', speakerImageHeight);

    // set font size in image-super-wrapper to the same as text-super font size
    $('.image-super-wrapper').find('h1, h2, h3, h4, h5, h6').css('font-size', $('.text-super').css('font-size'));
    $('.image-super-wrapper').find('h1, h2, h3, h4, h5, h6').css('font-variation-settings', $('.text-super').css('font-variation-settings'));
}

function calcVariableFontAttr() {
    const $elements = $('.text-super');
    const $dateElement = $('.text-super.middle');

    const maxFontWidth = 580;
    const minFontSize = 94;
    const maxFontSize = 208;
    const minViewportWidth = 320;
    const maxViewportWidth = 1920;
    const fontWeight = 900;

    // use date element for calculation
    // use style for all elements afterwards
    const viewportWidth = $(window).width();

    let fontWidth = (viewportWidth - minViewportWidth) * maxFontWidth / (maxViewportWidth - minViewportWidth);
    if (fontWidth < 0) {
        fontWidth = 0;
    } else if (fontWidth > maxFontWidth) {
        fontWidth = maxFontWidth;
    }

    let fontSize = (viewportWidth + minViewportWidth) * maxFontSize / (maxViewportWidth - minViewportWidth);
    if (fontSize < minFontSize) {
        fontSize = minFontSize;
    } else if (fontSize > maxFontSize) {
        fontSize = maxFontSize;
    }

    $elements.each((i, element) => {
        $(element).css("font-variation-settings", "\'wdth\' " + fontWidth + ", \'wght\' " + fontWeight);
        $(element).css("font-size", fontSize + 'px');
    });
}

initComponents();
