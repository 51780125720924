export default function ToTopBtn() {
    this.$btn = $('#to-top-btn');

    this.scrollTopDistanceToShowBtn = 300;
    this.scrollDuration = 250;
    this.fadeDuration = 200;
    this.init();
}

ToTopBtn.prototype.init = function () {
    /**
     * click event for mobile menu toggle button
     */
    this.$btn.on('click', (event) => {
        event.preventDefault();
        $('html, body').animate({"scrollTop": "0px"}, this.scrollDuration);
    });

    /**
     * show/hide button on document scroll
     */
    $(document).on('scroll', (event) => {
        this.onDocumentScroll();
    });
};

ToTopBtn.prototype.onDocumentScroll = function () {
    if ($(document).scrollTop() > this.scrollTopDistanceToShowBtn) {
        this.$btn.fadeIn(this.fadeDuration);
    } else {
        this.$btn.fadeOut(this.fadeDuration);
    }
};
