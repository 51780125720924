export default function AccordionElement(el) {
    this.activeClassName = 'active';
    this.animationDuration = 300;
    this.scrollDuration = 300;

    this.$el = $(el);
    this.$header = this.$el.find('.accordion-header');
    this.$content = this.$el.find('.accordion-content');

    this.bindEvents();
}

AccordionElement.prototype.bindEvents = function bindEvents() {
    this.$header.on('click', () => {
        if (this.$el.hasClass(this.activeClassName)) {
            this.$content.slideUp(this.animationDuration);
        } else {
            this.$content.slideDown(this.animationDuration);

            const headerOffset = this.$header.offset();
            const scrollTop = parseInt(headerOffset.top);
            $('html, body').animate({"scrollTop": scrollTop + 'px'}, this.scrollDuration);
        }
        this.$el.toggleClass(this.activeClassName);
    });
};
