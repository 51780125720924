export default function AccordionMenu(domElement) {
    this.$el = $(domElement);
    this.$menuItems = this.$el.find('li');
    this.$content = this.$el.next('.content');

    if (!this.$menuItems[0]) {
        console.log('[AccordionMenu] Error: No menu items found!');
    }

    if (!this.$content[0]) {
        console.log('[AccordionMenu] Error: No content element found!');
    }

    this.slideUpDuration = 300;
    this.slideDownDuration = 400;
    this.scrollDuration = 300;
    this.activeClassName = 'active';
    this.init();
}

AccordionMenu.prototype.init = function () {
    this.$menuItems.on('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        if ($(event.target).hasClass(this.activeClassName)) {
            this.hideContent();
        } else {
            // if ($('.content:visible')[0]) {
            //     $('.content:visible').slideUp(300, () => {
            //         showContent();
            //     });
            // } else {
            this.showContent(event);
            // }
        }
    });
};

AccordionMenu.prototype.hideContent = function () {
    // unmark clicked menu items
    this.$menuItems.removeClass(this.activeClassName);

    // scroll to top
    $('html, body').animate({"scrollTop": '0px'}, this.scrollDuration, () => {
        // slide up content element
        this.$content.slideUp(this.slideUpDuration);
    });

};

AccordionMenu.prototype.showContent = function (event) {
    // unmark clicked menu items
    this.$menuItems.removeClass(this.activeClassName);

    // mark clicked menu item
    // this.$el.addClass('active');
    $(event.target).addClass(this.activeClassName);

    let clickedItemIndex = 0;

    // check which item is clicked and decide where to scroll
    this.$menuItems.each((index, element) => {
        if (element === event.target) {
            clickedItemIndex = index;
        }
    });

    // slide down content element
    this.$content.slideDown(this.slideDownDuration, () => {
        // scroll to clicked menu
        let scrollTop = 0;

        if (clickedItemIndex === 0) {
            const menuOffset = this.$el.offset();
            scrollTop = parseInt(menuOffset.top + 2);
        } else {
            const $section = $(this.$content.find('section')[clickedItemIndex]);
            if ($section[0]) {
                const sectionOffset = $section.offset();
                scrollTop = parseInt(sectionOffset.top + 2);
            }
        }

        $('html, body').animate({"scrollTop": scrollTop + 'px'}, this.scrollDuration);
    });
};
