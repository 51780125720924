import Cookies from 'js-cookie';

export default function CookieNotice() {
    this.animationDuration = 300;

    this.$cookieNotice = $('#cookie-notice-wrapper');
    this.$btnAccept = this.$cookieNotice.find('a');

    if (Cookies.get('accept-cookie') !== '1') {
        this.$cookieNotice.fadeIn(this.animationDuration);
    }

    this.$btnAccept.on('click', (event) => {
        event.preventDefault();
        Cookies.set('accept-cookie', '1');
        this.$cookieNotice.fadeOut(this.animationDuration);
    });
}
